import { Role } from '@frk/graphql-types';

// TODO Clarify Role / user.roles[] : should a user really have multiple roles?

export type PhysicalRole = Exclude<Role, Role.Logged | Role.Unlogged | Role.All>;
export type AbstractRole = Exclude<Role, PhysicalRole>;
export type InsiderRole = Exclude<PhysicalRole, Role.Client | Role.Freelance | Role.Und>;

export function isPhysicalRole(role: Role): role is PhysicalRole {
  return role !== Role.Logged && role !== Role.Unlogged && role !== Role.All;
}
export function isAbstractRole(role: Role): role is AbstractRole {
  return !isPhysicalRole(role);
}

export function isInsiderRole(role: Role): role is InsiderRole {
  return isPhysicalRole(role) && role !== Role.Client && role !== Role.Freelance && role !== Role.Und;
}

// ! Passing the user object causes typing conflicts
// TODO Change first param to be user roles directly and not the user object
export function hasRoles(user: { roles: Role[] } | null, roles: Role[]) {
  if (!user || !user.roles) {
    return false;
  }

  return user.roles.length && roles.length ? roles.some(role => user.roles.includes(role)) : false;
}

// ? A user is considered insider if they have at least one insider role
export function isInsider(user: { roles: Role[] } | null) {
  if (!user || !user.roles) {
    return false;
  }

  return user.roles.some(isInsiderRole);
}
