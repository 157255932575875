import { deleteUndefinedProps, merge } from '@frk/helpers';
import { logger } from '../logger';
import { wait } from '../wait';

// TODO Inject the suffix instead of the environment key
// ? Define the suffix in workspaces' .env file
export const environmentsSuffix = {
  development: 'staging',
  production: undefined,
  staging: 'staging',
  testing: 'testing',
};

/**
 * Boolean attributes returned by getSettings() are `undefined` when false. Thus, we must ignore them
 * when comparing remote settings with in-code settings.
 */
export const booleanAttributes = ['advancedSyntax'];

const clients = {
  attributesToIndex: {
    fullname: { label: 'Prénom NOM du client', multiple: false },
    email: { label: 'Email du client', multiple: false },
    tel: { label: 'Téléphone du client', multiple: false },
    title: { label: 'Titre du client', multiple: false },
    businessTags: { label: 'Business tags', multiple: true },
    'company.name': { label: "Nom de l'entreprise du client", multiple: false },
    'company.siret': { label: "SIRET de l'entreprise du client", multiple: false },
    'company.activity': { label: "Activité de l'entreprise du client", multiple: false },
    'company.rcs': { label: "RCS de l'entreprise du client", multiple: false },
    'unordered(formattedAddress)': { label: "Adresse de l'entreprise du client", multiple: false },
    lastLoginAt: { label: 'Dernière connexion', multiple: false },
    createdAt: { label: 'Inscription', multiple: false },
  },
  attributesForFaceting: [
    'searchable(businessTags)',
    'client.status',
    'company.address.region',
    'searchable(company.name)',
    'businessDeveloper.fullname',
  ],
  customRanking: ['desc(lastLoginAt)'],
};

const freelancesReplicas = {
  freelances_inround_desc: {
    customRanking: ['desc(freelance.inround)'],
    relevancyStrictness: 80,
  },
  freelances_sendout_at_desc: {
    customRanking: ['desc(latestSendout.createdAt)'],
    relevancyStrictness: 80,
  },
  freelances_auth_created_at_asc: {
    customRanking: ['asc(createdAt)'],
    relevancyStrictness: 80,
  },
  freelances_auth_created_at_desc: {
    customRanking: ['desc(createdAt)'],
    relevancyStrictness: 80,
  },
  freelances_auth_last_signed_in_at_desc: {
    customRanking: ['desc(lastLoginAt)'],
    relevancyStrictness: 80,
  },
  freelances_availabilityDate_desc: {
    customRanking: ['desc(freelance.marketSituation.availableAtDate)'],
    relevancyStrictness: 80,
  },
  freelances_availabilityUpdatedAt_desc: {
    customRanking: ['desc(freelance.marketSituation.updatedAt)'],
    relevancyStrictness: 80,
  },
  // ? Index used to retrieve all skills in the autocomplete, and just that
  // ? Settings defined below are here to prevent data leaks in case a hacker would try to get more data
  freelances_skills: {
    attributesToRetrieve: ['skills'],
    attributesToHighlight: [],
    attributesForFaceting: ['skills'],
    attributesToIndex: {},
    paginationLimitedTo: 1,
    responseFields: ['hits'],
  },
};

const freelances = {
  distinct: true,
  attributeForDistinct: 'mongoObjectId',
  // == Attributes that can be highlighted
  attributesToIndex: {
    // ! 'fullname': First one to find 'David LONG', 'Alexandre CAO' or 'Cyril Forté'
    fullname: { label: 'Prénom NOM du freelance', multiple: false },
    // Attributes which may contain skills
    'unordered(title)': { label: 'Titre du freelance', multiple: false },
    keySkills: { label: 'Compétences clés du freelance', multiple: true }, // https://app.clickup.com/t/kv6716?comment=197859115
    'unordered(freelance.experiences.job)': { label: "Titre d'une expérience passée", multiple: true },
    'unordered(freelance.experiences.description)': {
      label: "Description d'une expérience passée",
      multiple: true,
    },
    'unordered(freelance.description)': { label: 'Description du freelance', multiple: false },
    'unordered(skills)': { label: 'Compétences du freelance', multiple: true },
    businessTags: { label: 'Business tags', multiple: true },
    'unordered(comment.text)': { label: "Commentaire d'un TAC", multiple: true },
    'unordered(freelance.educations.description)': {
      label: "Description d'une formation",
      multiple: true,
    },
    // Next attributes are not related to skills
    'freelance.educations.institution': { label: 'Organisme de formation', multiple: true },
    'freelance.experiences.name': { label: "Employeur d'une expérience passée", multiple: true },
    'unordered(formattedAddress)': { label: 'Adresse du freelance', multiple: false },
    email: { label: 'Email du freelance', multiple: false },
    tel: { label: 'Téléphone du freelance', multiple: false },
    'company.name': { label: "Nom de l'entreprise du freelance", multiple: false },
    linkedinUrl: { label: 'Profil LinkedIn du freelance', multiple: false },
  },
  attributesForFaceting: [
    'mongoObjectId',
    'searchable(businessTags)',
    'freelance.geographicAvailability.address.region',
    'freelance.activity',
    'freelance.jobs',
    'freelance.marketSituation.isListening',
    'freelance.marketSituation.availableAtDate',
    'freelance.marketSituation.availableAtPeriod',
    'freelance.marketSituation.updatedAt',
    'freelance.highestDegreeLevel',
    'searchable(freelance.educations.institution)',
    'searchable(freelance.experiences.name)',
    'freelance.inround',
    'freelance.location',
    'freelance.expectedContract',
    'freelance.price',
    'freelance.salary',
    'freelance.yearsOfExperience',
    'talentAdvocate.fullname',
    'searchable(skills)',
    'freelance.status',
    'freelance.timing',
    'migration.fieldNames',
    'noAddress',
    'hasSendout',
    'deleted',
  ],
  customRanking: ['desc(freelance.scores.weighted)'],
  separatorsToIndex: '+#',
  replicas: Object.keys(freelancesReplicas),
};

const missionsReplicas = {
  missions_priority_asc: {
    customRanking: ['asc(priority)'],
    hitsPerPage: 200,
    relevancyStrictness: 80,
  },
};

const missions = {
  attributesToIndex: {
    'description, profile, title': {
      label: 'Description, profil ou titre de la mission',
      multiple: false,
    },
    'pipelineSummary.winnerName': {
      label: 'Nom du freelance retenu pour la mission',
      multiple: false,
    },
    jobs: { label: 'Catégories métiers pour la mission', multiple: true },
    keySkills: { label: 'Compétences clées recherchées pour la mission', multiple: true },
    businessTags: { label: 'Business tags', multiple: false },
    'client.company.name, client.fullname, client.email': {
      label: "Nom de l'entreprise, Prénom NOM ou email du client",
      multiple: false,
    },
    'unordered(formattedAddress)': { label: 'Adresse de la mission', multiple: false },
    email: { label: 'Email du client', multiple: false },
    tel: { label: 'Téléphone du client', multiple: false },
  },
  attributesForFaceting: [
    'address.region',
    'duration',
    'talentAdvocate.fullname',
    'businessDeveloper.fullname',
    'searchable(keySkills)',
    'price',
    'status',
    'type',
    'searchable(businessTags)',
    'location',
    'migration.fieldNames',
    'priority',
  ],
  customRanking: ['desc(createdAt)'],
  replicas: Object.keys(missionsReplicas),
};

export const indexes = {
  clients,
  freelances,
  missions,
  ...Object.entries(freelancesReplicas).reduce(
    (acc, [name, settings]) => ({
      ...acc,
      [name]: merge(freelances, settings),
    }),
    {}
  ),
  ...Object.entries(missionsReplicas).reduce(
    (acc, [name, settings]) => ({
      ...acc,
      [name]: merge(missions, settings),
    }),
    {}
  ),
};

const sharedIndexSettings = {
  // Base
  minWordSizefor1Typo: 4,
  minWordSizefor2Typos: 8,
  hitsPerPage: 20,
  paginationLimitedTo: 1000,
  maxValuesPerFacet: 100,
  version: 2,
  numericAttributesToIndex: null,
  attributesToRetrieve: null,
  unretrievableAttributes: null,
  optionalWords: null,
  attributesToSnippet: null,
  attributesToHighlight: null,
  attributeForDistinct: null,
  exactOnSingleWordQuery: 'attribute',
  removeWordsIfNoResults: 'none',
  queryType: 'prefixLast',
  highlightPreTag: '<em>',
  highlightPostTag: '</em>',
  snippetEllipsisText: '',
  alternativesAsExact: ['ignorePlurals', 'singleWordSynonym'],
  customRanking: null,
  separatorsToIndex: '',
  advancedSyntax: true,
  queryLanguages: ['fr'],
  removeStopWords: true,
  ranking: ['typo', 'words', 'filters', 'attribute', 'proximity', 'exact', 'geo', 'custom'],
};

export function isAlgoliaVirtualIndex(indexName) {
  const [indexNameWithoutEnv] = indexName.split('-');

  return [...Object.keys(freelancesReplicas), ...Object.keys(missionsReplicas)].includes(indexNameWithoutEnv);
}

/**
 * Virtual index supports a subset of the available index settings.
 *
 * @param {Object} settings
 * @see https://www.algolia.com/doc/guides/managing-results/refine-results/sorting/in-depth/replicas/#virtual-replicas-supported-parameters-subset
 */
export function cleanAlgoliaSettings({ settings, isVirtualIndex }) {
  const unsupportedAttributesInVirtualIndex = [
    'attributeForDistinct',
    'attributesForFaceting',
    'attributesToIndex',
    'camelCaseAttributes',
    'customNormalization',
    'decompoundedAttributes',
    'disableExactOnAttributes',
    'disablePrefixOnAttributes',
    'disableTypoToleranceOnAttributes',
    'disableTypoToleranceOnWords',
    'facetingAfterDistinct',
    'indexLanguages',
    'keepDiacriticsOnCharacters',
    'numericAttributesForFiltering',
    'numericAttributesToIndex',
    'optionalWords',
    'searchableAttributes',
    'separatorsToIndex',
    'userData',
    'ranking',
    'replicas',
  ];

  return deleteUndefinedProps(
    isVirtualIndex
      ? Object.entries(settings).reduce(
          (acc, [attrName, attrValue]) => ({
            ...acc,
            [attrName]: unsupportedAttributesInVirtualIndex.includes(attrName) ? undefined : attrValue,
          }),
          {}
        )
      : settings
  );
}

// TODO Inject the suffix instead of the environment key
export function setupAlgoliaIndexesFor(env, testId) {
  if (!Object.keys(environmentsSuffix).includes(env)) {
    throw new Error('Invalid param env', { env });
  }

  if (env === 'testing' && !testId) {
    throw new Error('Missing param testId in test mode');
  }

  if (env !== 'testing' && testId) {
    throw new Error('Param testId should not be defined when env is not testing', { env, testId });
  }

  function getIndexName(indexName) {
    return [indexName, environmentsSuffix[env], testId].filter(Boolean).join('-');
  }

  function buildIndexesSettings() {
    return Object.entries(indexes).reduce(
      (acc, [name, settings]) => [
        ...acc,
        {
          name: getIndexName(name),
          settings: cleanAlgoliaSettings({
            settings: {
              ...sharedIndexSettings,
              ...settings,
              attributesToIndex:
                settings.attributesToIndex !== null
                  ? Object.keys(settings.attributesToIndex).map(attributeName => attributeName.replace(/\s/g, ''))
                  : settings.attributesToIndex,
              ranking: [...sharedIndexSettings.ranking, ...(settings.ranking || [])],
              replicas: (settings.replicas || []).map(replicaName => `virtual(${getIndexName(replicaName)})`),
            },
            isVirtualIndex: isAlgoliaVirtualIndex(name),
          }),
        },
      ],
      []
    );
  }

  async function setIndexesSettings(algoliaClient, indexesSettings) {
    async function setIndexSettings(algoliaIndex, settings, options = {}) {
      try {
        await algoliaIndex.setSettings(settings, options);
        logger.info(`[algolia] Index '${algoliaIndex.indexName}' has been successfully set.`);
        return true;
      } catch (err) {
        logger.error('[ERROR] Cannot set index:', { indexName: algoliaIndex.indexName });
        throw err;
      }
    }

    /**
     *
     * @param {string[]} indexNames
     * @param {number} retry
     * @return {string[]} A list of names of Algolia indexes that could not have been created before the pseudo-timeout (~ 1000ms x retry).
     */
    async function getNotCreatedIndexNames(indexNames = indexesSettings.map(({ name }) => name), retry = 10) {
      const remoteIndexesSettings = await Promise.all(
        indexNames.map(async name => {
          let settings;

          try {
            settings = await algoliaClient.initIndex(name).getSettings();
          } catch (err) {
            // Do nothing as getSettings() throws (by design) an exception when the index is not yet created
          }

          return {
            name,
            settings,
          };
        })
      );
      const notCreatedIndexNames = remoteIndexesSettings
        .filter(({ settings }) => !(typeof settings === 'object' && Object.keys(settings).length > 0))
        .map(({ name }) => name);

      if (notCreatedIndexNames.length === 0 || retry < 0) {
        return notCreatedIndexNames;
      }

      await wait(1000);

      return getNotCreatedIndexNames(notCreatedIndexNames, retry - 1);
    }

    // First create the indexes: https://github.com/algolia/algoliasearch-client-php/issues/299#issuecomment-319849590
    // We only need the replicas declaration to create the Algolia index
    await Promise.all(
      indexesSettings.map(({ name, settings }) =>
        setIndexSettings(algoliaClient.initIndex(name), { replicas: settings.replicas })
      )
    );

    // Then we define the Algolia index settings
    await Promise.all(
      indexesSettings.map(({ name, settings }) =>
        setIndexSettings(algoliaClient.initIndex(name), settings, { forwardToReplicas: false })
      )
    );

    // Ensures that all the Algolia indexes are effectively created on Algolia
    const notCreatedIndexNames = await getNotCreatedIndexNames();

    if (notCreatedIndexNames.length === 0) {
      logger.info('[algolia] All indexes have been set on the Algolia server.');
    } else {
      logger.error(
        `[algolia] Some indexes are not yet created on the Algolia server: ${notCreatedIndexNames.join(', ')}`
      );
    }
  }

  return {
    getIndexName,
    buildIndexesSettings,
    setIndexesSettings,
  };
}

export async function deleteTestIndexes(algoliaClient) {
  const indices = await algoliaClient.listIndices();

  const testingIndices = indices.items.filter(index => {
    const [indexRootName, createdAtTms] = index.name.split('-testing-');
    const halfHourMs = 1800 * 1000;

    return indexRootName && createdAtTms && Number.parseInt(createdAtTms, 10) < Date.now() - halfHourMs;
  });

  // Primary indices don't have a `primary` key
  const primaryIndices = testingIndices.filter(index => !index.primary);
  const replicaIndices = testingIndices.filter(index => index.primary);

  // Delete primary indices first
  algoliaClient.multipleBatch(primaryIndices.map(index => ({ indexName: index.name, action: 'delete' }))).wait();

  console.log('Deleted testing primary indices.');

  // Now, delete replica indices
  algoliaClient.multipleBatch(replicaIndices.map(index => ({ indexName: index.name, action: 'delete' }))).wait();

  console.log('Deleted testing replica indices.');
}
